import React from 'react'
import "./click-out.css"
import useWindowDimensions from "../../../helpers/hooks/windowDemensions";

const ClickOut = ({show, onClose, onCloseAll}) => {
    const {height, width} = useWindowDimensions();

    return (
        <div className={`click-out ${show ? "show" : ""}`}>
            <div onClick={e => e.stopPropagation()} className="click-out-content">
                <div className="click-out-header">
                    <h5>דלג על השלבים</h5>
                </div>
                <div className="click-out-body">
                    {width > 767 ? (
                        <p>האם אתה בטוח שתרצה לדלג על השלבים? יכול<br/> להיות שיהיו בעתיד הטבות שלא יגיעו אליך<br/></p>
                    ) : (
                        <p>האם אתה בטוח שתרצה לדלג על<br/> השלבים? יכול להיות שיהיו בעתיד<br/> הטבות שלא יגיעו אליך</p>
                    )}
                </div>
                <div className="click-out-footer">
                    <button onClick={onClose} className="click-out-button-close">
                        <p className="click-out-text-button">המשך במילוי</p>
                    </button>
                    <p className="click-out-text" onClick={onCloseAll}>דלג לתמונות</p>
                </div>
            </div>
        </div>
    )
}

export default ClickOut;