import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import axios from "axios";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  netPriceState,
  imageCountState,
} from "@atoms/priceCalc";
import { selectedPaymentMethod, selectedShippingAddress, validCoupon, addressLinkState } from "@atoms";
import { useSecondModal } from "@helpers/hooks/useSecondModal";
import "./style.css";
import { nextTuesday } from "@helpers/date";
import {OrderStatus} from "../../../constants/enums/orderStatus";
import {imagesLoadingState} from "../../../Recoil/Atoms";
import InputCoupon from "../../components/InputCoupon";
import AmountCharged from "../../../helpers/AmountCharged";
import {imagesBlobState} from "@atoms";
import HeartLoader from "../../HeartLoader";
import AddressLink from "../AddressLink";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SHIPMENT_COST = 29.90;

const Checkout = (props) => {
  const netPrice = useRecoilValue(netPriceState);
  const imagesBlob = useRecoilValue(imagesBlobState);
  const imageCount = useRecoilValue(imageCountState);
  const selectedPayment = useRecoilValue(selectedPaymentMethod);
  const selectedAddress = useRecoilValue(selectedShippingAddress);
  const selectedCoupon = useRecoilValue(validCoupon);

  const { show , handleCloseModal } = props;
  const modal = useSecondModal();

  const [numberOfImages,] = useState(5);
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const amountCharged = AmountCharged(imageCount + imagesBlob.length, numberOfImages, netPrice + (imagesBlob.length * 45), SHIPMENT_COST, selectedCoupon);

  const [isImagesLoading, setIsImagesLoading] = useRecoilState(imagesLoadingState);
  const [isClicked, setIsClicked] = useState(false);
  const [showAddressLink, setShowAddressLink] = useRecoilState(addressLinkState);

  const handleImagesLoading = () => {
    setIsClicked(true);
  };

  useEffect(() => {
    if (!isImagesLoading && isClicked) {
      setIsClicked(false);
      handleTranzilarPayment();
    }
  }, [isImagesLoading]);

  const creatOrder = async (data) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const res = await axios.post(`${BASE_URL}/user/createorder`, data, config);
      if (res.data.status === 200) {
        console.log('----success-----')
      }
      return res.data;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  const getCardExpDate = (month, year) => {
    const shortYear = year % 100;
    let shortYearStr = shortYear < 10 ? '0' + shortYear.toString() : shortYear.toString();
    return `${month}${shortYearStr}`;
  }

  const handleTranzilarPayment = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if(localStorage.getItem("orderId")) {
        const orderId = localStorage.getItem("orderId");

        const checkRes = await axios.get(`${BASE_URL}/user/checkorder/${orderId}`);
        console.log(checkRes);

        if(checkRes.data && checkRes.data.orderStatus === OrderStatus.NOT_PAID) {
          const response = await axios.post(BASE_URL + '/payment/do', {
            card: {
              no: selectedPayment.cardNumber.replace(/\s/g, ''),
              expdate: getCardExpDate(selectedPayment.expiriedMonth, selectedPayment.expiriedYear),
              cvv: selectedPayment.cvv,
              email: selectedAddress.email,
              id: selectedPayment.id,
            },
            amount: amountCharged,
          });

          if(response.data.status !== 200){
            throw new Error('Payment failed.');
          }

          const orderData = await axios.post(`${BASE_URL}/user/changeorderstatus/${orderId}`, {
            status: OrderStatus.PAID,
            amountCharged: amountCharged,
          });
          if (orderData.data.status === 400) {
            throw new Error('Completing order failed.');
          }

          localStorage.clear();
          history.push(`/payment-success/${orderId}`);
        } else {
          console.log("Order already paid or failed");
          localStorage.removeItem("orderId");
        }
      } else {
        const response = await axios.post(BASE_URL + '/payment/do', {
          card: {
            no: selectedPayment.cardNumber.replace(/\s/g, ''),
            expdate: getCardExpDate(selectedPayment.expiriedMonth, selectedPayment.expiriedYear),
            cvv: selectedPayment.cvv,
            email: selectedAddress.email,
            id: selectedPayment.id,
          },
          amount: amountCharged,
        });

        if(response.data.status !== 200){
          throw new Error('Payment failed.');
        }

        const orderData = await creatOrder({ ...selectedAddress, uid: localStorage.getItem('uniqueUserId'), finalAmount: amountCharged, orderStatus: OrderStatus.PAID });
        if (!orderData) {
          throw new Error('Creating order failed.');
        }
        
        if (window.flashy && typeof window.flashy.contacts.create === 'function') {
          const names = selectedAddress.fullName.trim().split(' ');
          const firstName = names[0];
          const lastName = names.length > 1 ? names.slice(1).join(' ') : '';
        
          // Add to Purchased list
          window.flashy.contacts.createOrUpdate({
            "email": selectedAddress.email,
            "first_name": firstName,
            "last_name": lastName,
            "total_spent": amountCharged,
            "frames_count": imageCount + imagesBlob.length,
            "order_id": orderData.oid,
            "lists": {
              28086: true 
            }
          });
        }

        localStorage.clear();
        history.push(`/payment-success/${orderData.oid}`);
      }
    } catch (err) {
      console.log(err);
      modal("open", 'errorCart');
    } finally {
      setLoading(false);
    }
  }

  const openAddCard = (name, card = false) => {
    if(!selectedAddress && card){
      return setShowAddressLink(true);
    }
    modal("open", name);
  };

  const renderCounts = () => {
    return <><span>{imageCount + imagesBlob.length} </span>בלנדס בגודל<span> 20x20</span></>
  }

  return (
    <div>
      <div
        id="myCart"
        className="cart_sidebar"
        style={{ left: show ? "0px" : "-370px" }}
      >
        <AddressLink show={showAddressLink}/>
        {isClicked && (
          <HeartLoader isLoading={isImagesLoading} showText={true} background={"white"} />
        )}
        <div className="checkout-card-wrapper">
          <div className="card-and-address-buttons">
            <button
              className={cn("", { checked: selectedAddress })}
              style={{ fontSize: "16px", fontWeight: selectedAddress ? 400 : 700, }}
              onClick={() => openAddCard("addAddress")}
            >
              {selectedAddress ? selectedAddress.city + " ," + selectedAddress.fullName : "הוספת פרטי משלוח"}
              {selectedAddress ? (
                <img src="/assets/file/images/Check.png" style={{ marginBottom: "2px", marginRight: "8px" }} alt="" />
              ) : (
                <img src={"/assets/images/form_address.png"} style={{ width: "25px", height: "25px" }} alt="" />
              )}
            </button>
            <button
              className={cn("", { checked: selectedPayment })}
              style={{ fontSize: "16px", fontWeight: selectedPayment ? 400 : 700 }}
              onClick={() => openAddCard("selectCard", true)}
            >
              {selectedPayment ? selectedPayment.hebrewType + ' ' + selectedPayment.cardNumber.substring(15, 19) : 'הוספת אמצעי תשלום'}
              {selectedPayment ? (
                <img src="/assets/file/images/Check.png" style={{ marginBottom: "2px", marginRight: "8px" }} alt="" />
              ) : (
                <img src={"/assets/images/form_card.svg"} style={{ width: "25px", height: "25px" }} alt="" />
              )}
            </button>
          </div>

          <div className="checkout-calculation">
            <div className="checkout-calculation__info" style={{ marginBottom: "0px" }}>
               {imageCount + imagesBlob.length <= 4 && <div>
                                  <p style={{ fontWeight:400, fontSize: "16px"}}>
                                  המשלוח צפוי
                                  להגיע אליכם עד יום
                                  <span style={{ fontWeight: 500, direction: "ltr !important" }}>{nextTuesday()}</span>
                                  </p>
                                  </div>}
                {imageCount + imagesBlob.length > 4 &&
                <div>
                <p style={{ fontWeight: 400, fontSize: "16px" }}>ההזמנה שלכם זכאית ל<span style={{ fontWeight: 500 }}>משלוח חינם,&nbsp;<br /></span>
                                  המשלוח צפוי
                                  להגיע אליכם עד יום
                <span style={{ fontWeight: 500, direction: "ltr !important" }}>{nextTuesday()}</span>
                </p>
                </div>
                }
              <img src="/assets/images/checkout_check.svg" alt="check" style={{ width: "15px", height: "15px" }} />
            </div>
            <InputCoupon imageCount={imageCount + imagesBlob.length} netPrice={netPrice + (imagesBlob.length * 45)} />
            <div className="price__table">
              <div className="price__table--row" style={{ fontWeight: "400" }}>
                <div>{netPrice + (imagesBlob.length * 45)}</div>
                <div style={{ direction: "rtl" }}>{renderCounts()}</div>
              </div>
              <div className="price__table--row" style={{ fontWeight: "400" }}>
                <div>29.90</div>
                <div>משלוח</div>
              </div>
              <div className="price__table--row" style={{ fontWeight: "400" }}>
                {imageCount + imagesBlob.length <= 4 && <div>-</div>}
                {imageCount + imagesBlob.length > 4 && <div>-29.90</div>}
                <div>הטבת כמות</div>
              </div>

              <div className="price__table--row total">
                <div style={{ fontSize: "16px", fontWeight: "700 !important" }}>
                  ₪&nbsp;
                  {amountCharged}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "700 !important" }}>סה”כ</div>
              </div>
            </div>
          </div>
          <div className="checkout-button-wrapper">
            {(!selectedAddress && !selectedPayment) && (
              <button
                className="checkout-button"
                onClick={() => openAddCard("addAddress")}
              >
                הזינו כתובת למשלוח
              </button>
            )}
            {(!selectedAddress && selectedPayment) && (
              <button
                className="checkout-button"
                onClick={() => openAddCard("addAddress")}
              >
                הזינו כתובת למשלוח
              </button>
            )}
            {(selectedAddress && !selectedPayment) && (
              <button
                className="checkout-button"
                onClick={() => openAddCard("selectCard")}
              >
                בחירת אמצעי תשלום
              </button>
            )}
            {isImagesLoading ? (
              (selectedAddress && selectedPayment) && (
                <button
                  className="checkout-button"
                  disabled={isLoading}
                  onClick={() => handleImagesLoading()}
                >
                  אישור הזמנה
                </button>
              )
            ) : (
              (selectedAddress && selectedPayment) && (
                <button
                  className="checkout-button"
                  disabled={isLoading}
                  onClick={handleTranzilarPayment}
                >
                  אישור הזמנה
                </button>
              )
            )}
          </div>
        </div>
      </div>
      {isLoading &&
        <div
          className="modal loaderbg"
          id="mainImageLoaderModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: isLoading }}
        >
          <div className="modal-dialog review-image-loader" role="document">
            <div className="loadingio-spinner-heart-btbrqan8295">
              <div className="ldio-kv0ui0pfesk">
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Checkout;
